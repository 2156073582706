import { Box, Grid } from "@chakra-ui/react";
import React from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useMatch,
} from "react-router-dom";

import { RequireUUIDParams } from "../../../components";
import { useOrgAccessMap } from "../../../hooks/useOrgFeatureAccess";
import { usePageTracker } from "../../../utils/googleAnalytics";
import MyInterviewRecordingOption from "../../components/MyInterviewsRecordingOption/MyInterviewRecordingOption";
import { FeatureName } from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import useCurrentUser from "../../hooks/useCurrentUser";
import { DefaultPageLayout } from "../../layouts";
import ExcludedUsers from "../users/ExcludedUsers";
import HiringTabsPage from "../users/HiringTabsPage";
import HiringTeamGroupInfoPage from "../users/HiringTeamGroupInfoPage";
import RolePoliciesPage from "../users/RolePoliciesPage";
import TeamInfoPage from "../users/TeamInfoPage";
import UserInfoPage from "../users/UserInfoPage";
import UsersListPage from "../users/UsersListPage";
import AdvancedConfigurations from "./AdvancedConfigurations";
import AiSettings from "./AiSettings";
import APISettings from "./APISettings";
import AtsSettings from "./AtsSettings";
import AutoJoinByPositionSettings from "./AutoJoinByPositionSettings";
import BullhornSettings from "./BullhornSettings";
import CalendarSettings from "./CalendarSettings";
import DataRetentionSettings from "./data-retention/DataRetentionSettings";
import DataRedactionSettings from "./DataRedactionSettings";
import ExcludeStageSettings from "./ExcludeStageSettings";
import GoogleMeetSettings from "./GoogleMeetSettings";
import GreenhouseSettings from "./GreenhouseSettings";
import IcimsSettings from "./IcimsSettings";
import LeverSettings from "./LeverSettings";
import MergeSettings from "./MergeSettings";
import MsftTeamsSettings from "./MsftTeamsSettings";
import NotificationsSettings from "./NotificationsSettings";
import OptInLandingPageSettings from "./OptInLandingPageSettings";
import PlanSettings from "./PlanSettings";
import ScoringSettings from "./ScoringSettings";
import SenseSettings from "./SenseSettings";
import SettingsNav from "./SettingsNav";
import SharedCalendarSettings from "./SharedCalendarSettings";
import SignUpAndSignIn from "./SignUpAndSignIn";
import SingleSignOnSettings from "./SingleSignOnSettings";
import SlackSettings from "./SlackSettings";
import SmartrecruitersSettings from "./SmartrecruitersSettings";
import TemplateSettings from "./TemplateSettings";
import UserSettings from "./UserSettings";
import VideoSettings from "./VideoSettings";
import ZoomAppSettings from "./ZoomAppSettings";
import ZoomNotetakerSettings from "./ZoomNotetakerSettings";
import ZoomOrgSettings from "./ZoomOrgSettings";

const SettingsPage: React.FC = () => {
  usePageTracker("user_settings");
  const currentUser = useCurrentUser();
  const isUsersListPage = !!useMatch("/settings/users/*");
  const planTemplatesFlagEnabled = useFeatureFlag("plan:custom-templates");

  const accessMap = useOrgAccessMap(currentUser);

  return (
    <DefaultPageLayout>
      <Grid
        background="white"
        minH="100%"
        gap="8"
        pr="8"
        overflowX="auto"
        templateColumns={`280px minmax(auto, ${
          isUsersListPage ? 1600 : 1200
        }px)`}
      >
        <SettingsNav currentUser={currentUser} />
        <Box pt="7">
          <Routes>
            <Route
              path="/user"
              element={<UserSettings currentUser={currentUser} />}
            />
            <Route
              path="/user/notifications"
              element={<NotificationsSettings currentUser={currentUser} />}
            />
            <Route path="/calendar" element={<CalendarSettings />} />
            <Route path="/templates" element={<TemplateSettings />} />
            <Route
              path="/user/zoom"
              element={<ZoomAppSettings currentUser={currentUser} />}
            />
            <Route path="/users/list" element={<UsersListPage />} />
            <Route
              path="/users/info/:id"
              element={
                <RequireUUIDParams
                  element={<Navigate to="/not-found" />}
                  paramNames={["id"]}
                >
                  <UserInfoPage />
                </RequireUUIDParams>
              }
            />
            <Route
              path="/users/teams"
              element={<Navigate to="/users/hiring/teams" replace />}
            />
            <Route
              path="/users/teams/info/:id"
              element={<HiringSettingsPageRedirect />}
            />
            <Route path="/users/hiring" element={<HiringTabsPage />} />
            <Route path="/users/hiring/:tabName" element={<HiringTabsPage />} />
            <Route
              path="/users/hiring/teams/info/:id"
              element={
                <RequireUUIDParams
                  paramNames={["id"]}
                  element={<Navigate to="/not-found" />}
                >
                  <TeamInfoPage />
                </RequireUUIDParams>
              }
            />
            <Route
              path="/users/hiring/team_groups/info/:id"
              element={
                <RequireUUIDParams
                  paramNames={["id"]}
                  element={<Navigate to="/not-found" />}
                >
                  <HiringTeamGroupInfoPage />
                </RequireUUIDParams>
              }
            />

            {planTemplatesFlagEnabled &&
              currentUser?.organization?.planEnabled &&
              currentUser.planUserRole?.canManageOrganizationSettings && (
                <Route
                  path="/plan"
                  element={<PlanSettings currentUser={currentUser} />}
                />
              )}
            {currentUser.userRole?.canManageUserRoles && (
              <>
                <Route path="/users/roles" element={<RolePoliciesPage />} />
                <Route
                  path="/users/roles/:tabName"
                  element={<RolePoliciesPage />}
                />

                <Route path="/users/excluded" element={<ExcludedUsers />} />
              </>
            )}
            <Route
              path="/users/*"
              element={<Navigate to="/settings/users/list" replace />}
            />
            <Route
              path="/organization"
              element={<SignUpAndSignIn currentUser={currentUser} />}
            />
            <Route
              path="/shared-calendars"
              element={<SharedCalendarSettings currentUser={currentUser} />}
            />
            {accessMap?.get(FeatureName.JoinByPosition)?.isVisible && (
              <Route
                path="/auto-join-by-position"
                element={<AutoJoinByPositionSettings />}
              />
            )}
            {accessMap?.get(FeatureName.AtsUserLevelImport)?.isVisible && (
              <Route
                path="/my-interview-join-options"
                element={<MyInterviewRecordingOption />}
              />
            )}
            {accessMap?.get(FeatureName.ComplianceCheck)?.isVisible && (
              <Route
                path="/compliance-check"
                element={<DataRedactionSettings />}
              />
            )}
            {accessMap?.get(FeatureName.DataRetention)?.isVisible &&
              [
                "/data-retention",
                "/data-retention/candidates",
                "/data-retention/interviews",
              ].map((path) => (
                <Route
                  key={path}
                  path={path}
                  element={<DataRetentionSettings />}
                />
              ))}
            {accessMap?.get(FeatureName.CandidateOptIn)?.isVisible && (
              <Route path="/opt-in" element={<OptInLandingPageSettings />} />
            )}
            {accessMap?.get(FeatureName.Scoring)?.isVisible && (
              <Route path="/scoring" element={<ScoringSettings />} />
            )}
            <Route path="/exclude-stage" element={<ExcludeStageSettings />} />
            <Route
              path="/advanced"
              element={<AdvancedConfigurations currentUser={currentUser} />}
            />
            <Route
              path="/video"
              element={<VideoSettings currentUser={currentUser} />}
            />
            {accessMap?.get(FeatureName.ZoomNative)?.isVisible && (
              <Route
                path="/zoom"
                element={<ZoomOrgSettings currentUser={currentUser} />}
              />
            )}
            {accessMap?.get(FeatureName.Zoom)?.isVisible && (
              <Route
                path="/zoom-notetaker"
                element={<ZoomNotetakerSettings currentUser={currentUser} />}
              />
            )}
            {accessMap?.get(FeatureName.GoogleMeet)?.isVisible && (
              <Route
                path="/google-meet"
                element={
                  <GoogleMeetSettings organization={currentUser.organization} />
                }
              />
            )}
            {accessMap?.get(FeatureName.MsTeams)?.isVisible && (
              <Route
                path="/msft-teams"
                element={
                  <MsftTeamsSettings organization={currentUser.organization} />
                }
              />
            )}
            <Route
              path="/ats"
              element={<AtsSettings currentUser={currentUser} />}
            />
            {accessMap?.get(FeatureName.Greenhouse)?.isVisible && (
              <Route
                path="/greenhouse"
                element={<GreenhouseSettings currentUser={currentUser} />}
              />
            )}
            {accessMap?.get(FeatureName.Lever)?.isVisible && (
              <Route
                path="/lever"
                element={<LeverSettings currentUser={currentUser} />}
              />
            )}
            {accessMap?.get(FeatureName.Smartrecruiters)?.isVisible && (
              <Route
                path="/smartrecruiters"
                element={<SmartrecruitersSettings />}
              />
            )}
            {accessMap?.get(FeatureName.Icims)?.isVisible && (
              <Route path="/icims" element={<IcimsSettings />} />
            )}
            {accessMap?.get(FeatureName.Bullhorn)?.isVisible && (
              <Route
                path="/bullhorn"
                element={<BullhornSettings currentUser={currentUser} />}
              />
            )}
            {accessMap?.get(FeatureName.Merge)?.isVisible && (
              <Route path="/other-ats" element={<MergeSettings />} />
            )}
            {accessMap?.get(FeatureName.OktaAuth)?.isVisible && (
              <Route
                path="/sso"
                element={<SingleSignOnSettings currentUser={currentUser} />}
              />
            )}
            <Route
              path="/slack"
              element={<SlackSettings currentUser={currentUser} />}
            />
            {accessMap?.get(FeatureName.RestApi)?.isVisible && (
              <Route
                path="/rest"
                element={<APISettings currentUser={currentUser} />}
              />
            )}
            {accessMap?.get(FeatureName.Sense)?.isVisible && (
              <Route path="/sense" element={<SenseSettings />} />
            )}
            <Route
              path="/ai"
              element={<AiSettings currentUser={currentUser} />}
            />
            <Route path="*" element={<Navigate to="/not-found" />} />
          </Routes>
        </Box>
      </Grid>
    </DefaultPageLayout>
  );
};

const HiringSettingsPageRedirect = (): JSX.Element => {
  const { pathname } = useLocation();
  const [id] = pathname.split("/").slice(-1);

  return <Navigate to={`/users/hiring/teams/info/${id}`} replace />;
};

export default SettingsPage;
